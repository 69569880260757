.deleteFav{

    cursor: pointer;
    color: red;
    font-weight: 500;
    background-color: transparent;
    border: none;
    font-size: 17px;
}
.FavoriteIcon{
    background-color: transparent;
    color: var(--color1);
    border: none;
    font-size: 20px;
    cursor: pointer;
}
.FavoriteIcon strong{
    display: none;
   
}
@media (max-width: 1024px) {
    .FavoriteIcon{
        color: var(--blanco);
        
    }
    
}